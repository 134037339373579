<template>
  <SlModal
    :id="id"
    :title="$t('Web.Users.EditUser', { 1: name })"
    @created="onCreated"
    @on-enter="handleSave"
    @hide="onModalHide"
  >
    <div class="modal-content users-form">
      <div
        v-if="isForMigration"
        class="users-form__row"
      >
        <SlInput
          v-model="email"
          :label="$t('EditUserInfo.Ui.lbEmail')"
        />
      </div>
      <template v-else>
        <div class="users-form__row">
          <SlSelect
            v-model="role"
            :options="roles"
            label="text"
            :disabled="isRoleChangeDisabled"
            :select-label="$t('User.Ui.lbRole')"
          />
        </div>
        <div class="users-form__row">
          <SlSelect
            v-model="catalog"
            :options="catalogs"
            label="text"
            :select-label="$t('User.Ui.lbPermissionGroup')"
          />
        </div>
      </template>
    </div>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton @click="handleSave">
          {{ $t('Common.Save') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapGetters } from 'vuex';
import { modal } from '@/mixins/modal';

export default {
  name: 'EditUserModal',
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      email: '',
      name: '',
      userId: '',
      role: 0,
      catalog: 0,
      isForMigration: false,
      saveHandler: null
    };
  },
  computed: {
    ...mapGetters({
      roles: 'userAccess/rolesItems',
      catalogs: 'userAccess/permissionsItems'
    }),
    isRoleChangeDisabled() {
      if (!this.$sl_hasReadTeamAccess || !this.$sl_isUserAdmin) {
        return true;
      }

      const userInfo = this.$sl_teamMembers.find((member) => member.id === this.userId);
      const adminRoleId = this.authState.teamState?.roles?.find((role) => role.key === 'Admin')?.id;

      return userInfo?.roleIds?.includes(adminRoleId);
    }
  },
  methods: {
    onCreated({ userId, name, role, catalog, isForMigration, email, saveCallback }) {
      this.userId = userId;
      this.email = email;
      this.name = name;
      this.role = role;
      this.catalog = catalog;
      this.isForMigration = isForMigration;
      this.saveHandler = saveCallback;
    },
    onModalHide() {
      this.userId = '';
      this.email = '';
      this.name = '';
      this.role = 0;
      this.catalog = 0;
      this.isForMigration = false;
      this.saveHandler = null;
    },
    handleSave() {
      if (this.saveHandler) {
        this.saveHandler({
          email: this.email,
          role: this.role,
          catalog: this.catalog
        });
      }

      this.hideModal(this.id);
    },
    handleCancel() {
      this.hideModal(this.id);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/style/components/users/users-form.scss";
</style>
