<template>
  <SlModal
    :id="id"
    :title="$t('WiMultiuserTab.NewUser')"
    persistent
    @on-enter="handleCreate"
    @show="onModalShow"
    @hide="onModalHide"
  >
    <div class="modal-content users-form">
      <ValidationObserver
        ref="observer"
        class="users-form__row"
      >
        <SlValidate
          v-slot="{ invalid }"
          key="user"
          vid="user"
          rules="required"
        >
          <SlSelect
            v-model="userId"
            :select-label="$t('User.Ui.lbUsername')"
            :options="membersToAdd"
            label="name"
            track-by="id"
            searchable
            required
            :is-invalid="invalid"
          />
        </SlValidate>
      </ValidationObserver>
      <div class="users-form__row">
        <SlSelect
          v-model="role"
          label="text"
          :disabled="isRoleSetDisabled"
          :options="roles"
          :select-label="$t('User.Ui.lbRole')"
        />
      </div>
      <div class="users-form__row">
        <SlSelect
          v-model="catalog"
          :options="catalogs"
          label="text"
          :select-label="$t('User.Ui.lbPermissionGroup')"
        />
      </div>
    </div>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton @click="handleCreate">
          {{ $t('Web.Users.AddUser') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapGetters } from 'vuex';
import { modal } from '@/mixins/modal';

export default {
  name: 'AddUserModal',
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    },
    roles: {
      type: Array,
      required: true
    },
    catalogs: {
      type: Array,
      required: true
    },
    takenUserIds: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      catalog: 0,
      role: 0,
      userId: null
    };
  },
  computed: {
    ...mapGetters({
      tenantUsers: 'initialization/tenantUsers'
    }),
    membersToAdd() {
      return this.tenantUsers.filter(
        member => !this.takenUserIds.includes(member.id)
      );
    },
    isRoleSetDisabled() {
      if (!this.$sl_hasReadTeamAccess || !this.$sl_isUserAdmin) {
        return true;
      }

      const userInfo = this.$sl_teamMembers.find((member) => member.id === this.userId);
      const adminRoleId = this.authState.teamState?.roles?.find((role) => role.key === 'Admin')?.id;

      return userInfo?.roleIds?.includes(adminRoleId);
    }
  },
  watch: {
    isRoleSetDisabled(isDisabled) {
      if (isDisabled) {
        this.role = 0;
      }
    }
  },
  methods: {
    onModalShow() {
      this.$sl_loadTeamMembers();
    },
    onModalHide() {
      this.catalog = 0;
      this.role = 0;
      this.userId = null;
    },
    async handleCreate() {
      const valid = await this.$refs.observer.validate();

      if (!valid) {
        return;
      }

      this.$emit('create', {
        userId: this.userId,
        permissionId: this.catalog,
        roleId: this.role
      });

      this.hideModal(this.id);
    },
    handleCancel() {
      this.hideModal(this.id);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/style/components/users/users-form.scss";
</style>
