<template>
  <SlDropdown
    v-bind="$attrs"
    placement="bottom-end"
  >
    <template #target>
      <SlButton
        :disabled="!isMigration && !$sl_hasAccess($sl_features.manageUsers)"
        variant="tertiary"
        color="grey"
        size="2xs"
        icon
      >
        <icon
          data="@icons/vertical-dots.svg"
          class="size-20 color-grey"
        />
      </SlButton>
    </template>
    <template #content>
      <SlDropdownOption
        v-for="action in allowedActions"
        :key="action.flag"
        select-close
        @select="action.action(user, isMigration)"
      >
        <template #prepend>
          <icon
            v-if="action.icon"
            class="fill-off size-16 stroke-icon--grey"
            :data="getIcon(action.icon)"
          />
        </template>
        {{ action.label }}
      </SlDropdownOption>
    </template>
  </SlDropdown>
</template>

<script>
import { mapActions } from 'vuex';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'UserActionsDropdown',
  mixins: [modal, access],
  props: {
    actions: {
      type: Number,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    isMigration: Boolean
  },
  data() {
    return {
      actionsConfig: [
        {
          flag: 0,
          label: this.$t('Web.CustomFields.EditAction'),
          icon: 'pen',
          action: (data, isForMigration) => this.handleEditUser(data, isForMigration)
        },
        {
          flag: 1,
          label: this.$t('Web.CustomFields.DeleteAction'),
          icon: 'trash',
          action: (data) => this.handleDeleteUser(data)
        }
      ]
    };
  },
  computed: {
    allowedActions() {
      return this.actionsConfig.filter(action => this.actions & 1 << action.flag);
    }
  },
  methods: {
    ...mapActions({
      deleteUser: 'userAccess/deleteUser',
      updateUser: 'userAccess/updateUser',
      updateOldUser: 'userAccess/updateOldUser'
    }),
    getIcon(icon) {
      return require(`@icons/${icon}.svg`);
    },
    getUpdatePayload(data) {
      return {
        roleId: data.role,
        permissionId: data.catalog,
        userId: data.rowId
      };
    },
    getOldUpdatePayload(data) {
      return {
        ...this.getUpdatePayload(data),
        email: data.email
      };
    },
    async saveEditUserModalCallback(data) {
      try {
        if (data.isForMigration) {
          await this.updateOldUser(this.getOldUpdatePayload(data));
        } else {
          await this.updateUser(this.getUpdatePayload(data));
        }
      } catch (e) {
        this.$store.dispatch('user/logout', { e, from: 'saveEditUserModalCallback' });
      }
    },
    handleEditUser(scope, isForMigration = false) {
      this.showModal(modalsId.EDIT_USER, {
        userId: scope.rowId,
        name: scope.row.Name.val,
        email: scope.row.Email.val,
        role: scope.row.RoleName.id,
        catalog: scope.row.CatalogName.id,
        isForMigration,
        saveCallback: ({ email, role, catalog }) => this.saveEditUserModalCallback({
          ...scope,
          email,
          role,
          catalog,
          isForMigration
        })
      });
    },
    handleDeleteUser(scope) {
      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Users.DeleteUser', { 1: scope.row.Name.val }),
        icon: 'style_warning',
        confirmText: this.$t('Web.Project.Delete'),
        confirmCallback: () => this.deleteUser(scope)
      });
    }
  }
};
</script>
